import EventEmitter from "events";
import { client } from "./client";
import axios from "axios";
import { self } from "../types/Selfs";
class Selfs extends EventEmitter {
    loaded: boolean | null;
    selfs: self[];
    constructor() {
        super();
        this.loaded = null;
        this.selfs = [];
    }
    async load() {
        if (!client.token) return;
        const req = await axios.get(`${client.API_URL}/selfs`, {
            headers: {
                Authorization: `${client.token}`
            }
        });
        if (req.data.ok) {
            this.selfs = req.data.bots;
            this.loaded = true;
            
        } else {
            this.loaded = false;
            this.selfs = [];
        }
        console.log(this.selfs);
        this.emit("load", this.selfs);
    }
}
const _selfs = new Selfs();
export default _selfs ;

interface selfsEvents {
    load: (selfs: self[]) => void;
}
declare interface Selfs {
    on<U extends keyof selfsEvents>(
        event: U, listener: selfsEvents[U]
    ): this;

    emit<U extends keyof selfsEvents>(
        event: U, ...args: Parameters<selfsEvents[U]>
    ): boolean;
}

