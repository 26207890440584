import ReactDOM from 'react-dom/client';
import {Route, createRoutesFromElements, createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './pages/home';
import Login from "./pages/login";
import Overview from "./pages/overview"
import Dashboard from "./pages/dashboard";
import Test from './pages/test';
import Player from './pages/player'; 
import './index.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Manage from './pages/manage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home></Home>} />
      <Route path="/api/login/:token" element={<Login/>} />
      <Route path="/api/login/:token/:redirect_uri" element={<Login/>} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/:id" element={<Overview/>} />
      <Route path="/dashboard/:id/manage" element={<Manage/>} />
      <Route path="/dashboard/:id/:guildId" element={<Player/>} />
      <Route path="/test" element={<Test/>} />  
      <Route path="*" element={<h1>Page not found</h1>} />
      </>
  )
);
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <RouterProvider router={router} />
    <ToastContainer />
    </>
)