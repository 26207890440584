import { Link } from "react-router-dom";
import { GuildsProps } from "../types/Guilds";
import { self } from "../types/Selfs";

interface guildProps {
    guilds: GuildsProps[],
    activeId: string | null,
    id: string,
    self: self
}

function GuildsIcones(guilds: guildProps) {
    return (
        <>
            <div className="bg-[#1b1b1b] w-16 flex flex-col items-center pt-4 p-4 overflow-y-scroll flex-shrink-0 scrollbar-hide h-full">
                <Link to={`/dashboard/${guilds.id}`}>
                    <div className="w-12 h-12 mb-2 flex items-center justify-center transition-all duration-200 cursor-pointer">
                        <img
                            src={guilds.self.avatar}
                            alt="avatar"
                            className={`w-full h-full object-cover ${guilds.activeId == null ?"sidebar_guild__images__active": "sidebar_guild__images"}`}
                        />
                    </div>
                </Link>

                <div className="w-8 border-b border-gray-500 mb-2"></div>

                {guilds.guilds.map((guild) => (
                    <Link to={`/dashboard/${guilds.id}/${guild.id}`}>
                        <div className="w-12 h-12 mb-2 flex items-center justify-center transition-all duration-200 cursor-pointer">
                            <img
                                src={guild.icon}
                                alt={guild.name}
                                className={`w-full h-full object-cover ${guilds.activeId ? guilds.activeId == guild.id ? "sidebar_guild__images__active" : "sidebar_guild__images" : "sidebar_guild__images"
                                    }`}
                            />
                        </div>
                    </Link>
                ))}
            </div>
        </>
    )

}

export default GuildsIcones