export default function LoadingCard(){
    return (
        <>
        
        <div className="w-72 bg-gray-800 text-white rounded-lg overflow-hidden shadow-lg font-sans animate-pulse">
        <div className="relative flex justify-center p-4 bg-gray-700">
            <div className="w-20 h-20 rounded-full bg-gray-600"></div>
            <span className="absolute bottom-2 right-2 w-5 h-5 rounded-full bg-gray-600"></span>
        </div>
        <div className="p-5">
            <div className="flex items-center justify-between mb-3">
                <div className="w-24 h-4 bg-gray-600 rounded"></div>
                <div className="w-12 h-4 bg-gray-600 rounded"></div>
            </div>
            <div className="mb-3">
                <div className="w-full h-4 bg-gray-600 rounded mb-2"></div>
                <div className="w-3/4 h-4 bg-gray-600 rounded"></div>
            </div>
            <div className="text-xs">
                <div className="mb-2">
                    <div className="w-24 h-4 bg-gray-600 rounded mb-1"></div>
                    <div className="w-36 h-4 bg-gray-600 rounded"></div>
                </div>
                <div className="mb-2">
                    <div className="w-24 h-4 bg-gray-600 rounded mb-1"></div>
                    <div className="w-36 h-4 bg-gray-600 rounded"></div>
                </div>
            </div>
        </div>
    </div>
    <div className="w-72 bg-gray-800 text-white rounded-lg overflow-hidden shadow-lg font-sans animate-pulse">
    <div className="relative flex justify-center p-4 bg-gray-700">
        <div className="w-20 h-20 rounded-full bg-gray-600"></div>
        <span className="absolute bottom-2 right-2 w-5 h-5 rounded-full bg-gray-600"></span>
    </div>
    <div className="p-5">
        <div className="flex items-center justify-between mb-3">
            <div className="w-24 h-4 bg-gray-600 rounded"></div>
            <div className="w-12 h-4 bg-gray-600 rounded"></div>
        </div>
        <div className="mb-3">
            <div className="w-full h-4 bg-gray-600 rounded mb-2"></div>
            <div className="w-3/4 h-4 bg-gray-600 rounded"></div>
        </div>
        <div className="text-xs">
            <div className="mb-2">
                <div className="w-24 h-4 bg-gray-600 rounded mb-1"></div>
                <div className="w-36 h-4 bg-gray-600 rounded"></div>
            </div>
            <div className="mb-2">
                <div className="w-24 h-4 bg-gray-600 rounded mb-1"></div>
                <div className="w-36 h-4 bg-gray-600 rounded"></div>
            </div>
        </div>
    </div>
</div>
<div className="w-72 bg-gray-800 text-white rounded-lg overflow-hidden shadow-lg font-sans animate-pulse">
        <div className="relative flex justify-center p-4 bg-gray-700">
            <div className="w-20 h-20 rounded-full bg-gray-600"></div>
            <span className="absolute bottom-2 right-2 w-5 h-5 rounded-full bg-gray-600"></span>
        </div>
        <div className="p-5">
            <div className="flex items-center justify-between mb-3">
                <div className="w-24 h-4 bg-gray-600 rounded"></div>
                <div className="w-12 h-4 bg-gray-600 rounded"></div>
            </div>
            <div className="mb-3">
                <div className="w-full h-4 bg-gray-600 rounded mb-2"></div>
                <div className="w-3/4 h-4 bg-gray-600 rounded"></div>
            </div>
            <div className="text-xs">
                <div className="mb-2">
                    <div className="w-24 h-4 bg-gray-600 rounded mb-1"></div>
                    <div className="w-36 h-4 bg-gray-600 rounded"></div>
                </div>
                <div className="mb-2">
                    <div className="w-24 h-4 bg-gray-600 rounded mb-1"></div>
                    <div className="w-36 h-4 bg-gray-600 rounded"></div>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}