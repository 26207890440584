import { useParams } from "react-router-dom";
import { player } from "../libs/player";
import { Voice } from "../types/Voices";
import selfdeafen from "../images/selfdeafen.png";
import selfmute from "../images/selfmute.png";
import serverdeafen from "../images/serverdeafen.png";
import servermute from "../images/servermute.png";
import video from "../images/video.png";
import streaming from "../images/live.png";
import { ChaneelOpned, ChannelHeden, LockedChannel } from "../images/icons/locked";
export default function GetVoices(voices: Voice[]){
    let { id, guildId } = useParams();
    
return (
    <>
            <div>
               {voices.sort((a, b) => a.position - b.position).map((voice) => (
                    <div
                        key={voice.id}
                        className="p-1 rounded-md"
                    >
                        <div onClick={() => player.joinVoice(id as string, voice.id, guildId as string)} className="flex items-center font-medium text-sm rounded-md p-2 hover:bg-gray-700 cursor-pointer space-x-2 text-gray-200">
                            <i className="ftext-gray-400 text-base">
                                {voice.showed &&<> 
                                {voice.joinable && <ChaneelOpned />}
                                {!voice.joinable && <LockedChannel/>}
                                </>}
                                {voice.showed == false && <>
                                    <div className="w-6 h-6 flex items-center justify-center ">
                                    <ChannelHeden/>
                                    </div>
                                </>}
                                </i>
                            <span className="truncate w-full">{voice.name}</span>
                        </div>
                        <ul className="ml-6 mt-1">
                            {voice.members.map((member) => (
                                <li
                                    key={member.id}
                                    className="flex items-center justify-between p-1 rounded-md hover:bg-gray-700 cursor-pointer"
                                >
                                    <div className="flex items-center space-x-2 w-full overflow-hidden">
                                        {member.avatar && (
                                            <div
                                                className="w-6 h-6 rounded-full bg-cover bg-center flex-shrink-0"
                                                style={{ backgroundImage: `url(${member.avatar})` }}
                                            />
                                        )}
                                        <span className="truncate text-white text-sm font-semibold">
                                            {member.displayusername || 'Unknown User'}
                                        </span>
                                    </div>
                                    <div className="flex space-x-1 text-gray-400 flex-shrink-0">
                                        {member.voice.serverMute && (
                                            <img src={servermute} alt="Muted Icon" className="w-4 h-4" />
                                        )}
                                        {member.voice.serverDeaf && (
                                            <img src={serverdeafen} alt="Deafened Icon" className="w-4 h-4" />
                                        )}
                                        {!member.voice.serverMute && member.voice.selfMute && (
                                            <img src={selfmute} alt="Muted Icon" className="w-4 h-4" />
                                        )}
                                        {!member.voice.serverDeaf && member.voice.selfDeaf && (
                                            <img src={selfdeafen} alt="Deafened Icon" className="w-4 h-4" />
                                        )}
                                        {member.voice.selfVideo && (
                                            <img src={video} alt="Video Icon" className="w-4 h-4" />
                                        )}
                                        {member.voice.selfStream && (
                                            <img src={streaming} alt="Streaming Icon" className="w-8 h-4" />
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
    </>
)
}