

export default function VoicesLoading(){
return (
    <>
        <div>
        <div className="p-1 rounded-md animate-pulse">
        <div className="flex items-center font-medium text-sm rounded-md p-2 bg-gray-700 space-x-2">
            <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
            <div className="flex-1 h-4 bg-gray-600 rounded"></div>
        </div>
        <ul className="ml-6 mt-1">
            <li className="flex items-center justify-between p-1 rounded-md bg-gray-700">
                <div className="flex items-center space-x-2 w-full">
                    <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
                    <div className="flex-1 h-4 bg-gray-600 rounded"></div>
                </div>
            </li>
        </ul>
    </div>
    <div className="p-1 rounded-md animate-pulse">
        <div className="flex items-center font-medium text-sm rounded-md p-2 bg-gray-700 space-x-2">
            <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
            <div className="flex-1 h-4 bg-gray-600 rounded"></div>
        </div>
        <ul className="ml-6 mt-1">
            <li className="flex items-center justify-between p-1 rounded-md bg-gray-700">
                <div className="flex items-center space-x-2 w-full">
                    <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
                    <div className="flex-1 h-4 bg-gray-600 rounded"></div>
                </div>
            </li>
        </ul>
    </div>
    <div className="p-1 rounded-md animate-pulse">
        <div className="flex items-center font-medium text-sm rounded-md p-2 bg-gray-700 space-x-2">
            <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
            <div className="flex-1 h-4 bg-gray-600 rounded"></div>
        </div>
    </div>
    <div className="p-1 rounded-md animate-pulse">
        <div className="flex items-center font-medium text-sm rounded-md p-2 bg-gray-700 space-x-2">
            <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
            <div className="flex-1 h-4 bg-gray-600 rounded"></div>
        </div>
    </div>
    <div className="p-1 rounded-md animate-pulse">
        <div className="flex items-center font-medium text-sm rounded-md p-2 bg-gray-700 space-x-2">
            <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
            <div className="flex-1 h-4 bg-gray-600 rounded"></div>
        </div>
        <ul className="ml-6 mt-1">
            <li className="flex items-center justify-between p-1 rounded-md bg-gray-700">
                <div className="flex items-center space-x-2 w-full">
                    <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
                    <div className="flex-1 h-4 bg-gray-600 rounded"></div>
                </div>
            </li>
            <li className="flex items-center justify-between p-1 rounded-md bg-gray-700">
                <div className="flex items-center space-x-2 w-full">
                    <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
                    <div className="flex-1 h-4 bg-gray-600 rounded"></div>
                </div>
            </li>
            <li className="flex items-center justify-between p-1 rounded-md bg-gray-700">
                <div className="flex items-center space-x-2 w-full">
                    <div className="w-6 h-6 bg-gray-600 rounded-full"></div>
                    <div className="flex-1 h-4 bg-gray-600 rounded"></div>
                </div>
            </li>
        </ul>
    </div>
</div>
    </>
)
}

