import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { GuildsProps } from '../types/Guilds';

interface ToggleSwitchProps {
  label: string;
  id: string;
  description: string;
  guilds: GuildsProps[];
  status: boolean;
  defaultselected: {
    enabled?: string[];
    disabled?: string[];
    audio?: string | null;
  };
  audioStatus?: boolean;
  enableGuilds: boolean;
  disabledGuilds: boolean;
  onSave: (data: { enabled: string[], disabled: string[], audio?: string | null }) => void;
  onStatusEdited: (status: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label,
  description,
  guilds,
  id,
  status,
  onSave,
  onStatusEdited,
  defaultselected,
  audioStatus,
  disabledGuilds,
  enableGuilds
}) => {
  const [enabled, setEnabled] = useState(status);
  const [isEditing, setIsEditing] = useState(false);
  const [guildsStatus, setGuildsStatus] = useState<{
    enabled: GuildsProps[];
    disabled: GuildsProps[];
    audio?: string | null;
  }>({ enabled: [], disabled: [], audio: null });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Utility function to convert Base64 to Blob
  const base64ToBlob = (base64Data: string, contentType: string): Blob => {
    const byteCharacters = atob(base64Data.split(',')[1]); // Remove the data URL prefix
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject('Failed to read file.');
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('audio/')) {
      try {
        const fileURL = URL.createObjectURL(file);

        // Create an audio element to check the duration
        const audio = new Audio(fileURL);
        audio.onloadedmetadata = () => {
          const duration = audio.duration;

          if (duration > 15) {
            alert('Please select an audio file with a duration of less than 15 seconds.');
            // Reset file input
            event.target.value = '';
            return;
          }

          // If the duration is less than 15 seconds, proceed with setting the file
          setSelectedFile(file);
          setAudioURL(fileURL);

          // Convert the file to Base64 and store it in the state
          readFileAsBase64(file)
            .then(base64Data => {
              setGuildsStatus(prevState => ({
                ...prevState,
                audio: base64Data
              }));
            })
            .catch(error => {
              alert('Error reading audio file.');
              console.error(error);
            });
        };
      } catch (error) {
        alert('Error reading audio file.');
        console.error(error);
      }
    } else {
      alert('Please select a valid audio file (mp3 or similar)');
    }
  };

  const handlePlayAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handlePauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  function setDefauldAudio() {
    if (defaultselected.audio) {
      const blob = base64ToBlob(defaultselected.audio, 'audio/mp3'); // Assuming mp3 type, adjust as needed
      const file = new File([blob], 'default-audio.mp3', { type: 'audio/mp3' });

      setSelectedFile(file);
      const fileURL = URL.createObjectURL(blob);
      setAudioURL(fileURL);
    }
  }
  useEffect(() => {
    setGuildsStatus({
      enabled: (defaultselected.enabled || []).map(guildId => guilds.find(guild => guild.id === guildId)) as GuildsProps[],
      disabled: (defaultselected.disabled || []).map(guildId => guilds.find(guild => guild.id === guildId)) as GuildsProps[],
      audio: defaultselected.audio || null
    });

    // Check if there is a default audio and convert it to a Blob if present
    setDefauldAudio()
  }, [defaultselected, guilds]);

  const handelCancelClick = () => {
    setGuildsStatus({
      enabled: (defaultselected.enabled || []).map(guildId => guilds.find(guild => guild.id === guildId)) as GuildsProps[],
      disabled: (defaultselected.disabled || []).map(guildId => guilds.find(guild => guild.id === guildId)) as GuildsProps[],
      audio: defaultselected.audio || null
    });
    setDefauldAudio()
  };

  const handelSaveClick = () => {
    onSave({
      enabled: guildsStatus.enabled.map(guild => guild.id),
      disabled: guildsStatus.disabled.map(guild => guild.id),
      audio: guildsStatus.audio || null // Save the binary audio data
    });
  };

  const handleSatatusEdited = () => {
    setEnabled(!enabled);
    onStatusEdited(!enabled);
  };

  const handleChangeEnabled = (selectedOptions: any) => {
    setGuildsStatus(prevState => ({
      ...prevState,
      enabled: selectedOptions || []
    }));
  };

  const handleChangeDisabled = (selectedOptions: any) => {
    setGuildsStatus(prevState => ({
      ...prevState,
      disabled: selectedOptions || []
    }));
  };

  // React Select custom styles
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#374151',
      borderColor: '#4B5563',
      color: 'white'
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#1F2937',
      zIndex: 100
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#3B82F6' : '#1F2937',
      color: state.isSelected ? 'white' : '#D1D5DB',
      padding: 10,
      display: 'flex',
      alignItems: 'center'
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#4B5563',
      color: 'white'
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'white'
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: '#9CA3AF',
      ':hover': {
        backgroundColor: '#6B7280',
        color: 'white'
      }
    })
  };

  // Map guilds to options that work with react-select
  const guildOptions = guilds.map(guild => ({
    value: guild.id,
    label: guild.name,
    icon: guild.icon,
    guild
  }));

  const formatOptionLabel = ({ label, icon }: any) => (
    <div className="flex items-center">
      {icon ? (
        <img src={icon} alt={label} className="w-4 h-4 rounded-full mr-2" />
      ) : (
        <div className="w-4 h-4 bg-gray-600 rounded-full mr-2"></div>
      )}
      <span>{label}</span>
    </div>
  );

  return (
    <div className="flex flex-col w-full p-4 bg-gray-800 text-white rounded-md shadow-md mb-4">
      {/* Toggle Header */}
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-semibold">{label}</h3>
          <p className="text-sm text-gray-400">{description}</p>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="px-3 py-1 bg-gray-700 text-white rounded hover:bg-gray-600"
            onClick={() => setIsEditing(!isEditing)}
          >
            Edit
          </button>
          <div
            className={`relative w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${enabled ? 'bg-blue-500' : 'bg-gray-500'
              }`}
            onClick={handleSatatusEdited}
          >
            <div
              className={`bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out ${enabled ? 'translate-x-6' : ''
                }`}
            />
          </div>
        </div>
      </div>

      {/* Expanded Options */}
      {isEditing && (
        <div className="mt-4 p-4 bg-gray-900 rounded-md border border-gray-700">
          <div className="grid grid-cols-1 gap-4">
            {/* Enabled Guilds */}
            {enableGuilds && (
              <div>
                <label className="block text-sm text-gray-400 mb-1">ENABLED SERVERS</label>
                <Select
                  isMulti
                  isDisabled={guildsStatus.disabled.length > 0}
                  id="select"
                  options={guildOptions}
                  value={guildsStatus.enabled.map(guild => ({
                    value: guild.id,
                    label: guild.name,
                    icon: guild.icon
                  }))}
                  onChange={selectedOptions =>
                    handleChangeEnabled(
                      selectedOptions.map((option: any) => ({
                        id: option.value,
                        name: option.label,
                        icon: option.icon
                      }))
                    )
                  }
                  styles={customStyles}
                  placeholder="Select Enabled Servers"
                  formatOptionLabel={formatOptionLabel}
                  classNamePrefix="custom-select-scrollbar"
                />
              </div>
            )}

            {/* Disabled Guilds */}
            {disabledGuilds && (
              <div>
                <label className="block text-sm text-gray-400 mb-1">DISABLED SERVERS</label>
                <Select
                  id="select"
                  isMulti
                  isDisabled={guildsStatus.enabled.length > 0}
                  options={guildOptions}
                  value={guildsStatus.disabled.map(guild => ({
                    value: guild.id,
                    label: guild.name,
                    icon: guild.icon
                  }))}
                  onChange={selectedOptions =>
                    handleChangeDisabled(
                      selectedOptions.map((option: any) => ({
                        id: option.value,
                        name: option.label,
                        icon: option.icon
                      }))
                    )
                  }
                  styles={customStyles}
                  placeholder="Select Disabled Servers"
                  formatOptionLabel={formatOptionLabel}
                  classNamePrefix="custom-select-scrollbar"
                />
              </div>
            )}

            {/* Audio Upload/Playback */}
            {audioStatus && (
              <>
                <div className="mb-4">
                  {!audioURL ? (
                    // Show upload button if no audio is uploaded
                    <div className="flex justify-center">
                      <label
                        htmlFor="audioUpload"
                        className="px-4 py-2 bg-blue-500 text-white rounded-md cursor-pointer hover:bg-blue-600 flex items-center"
                      >
                        <i className="fa fa-upload mr-2" /> Upload MP3/Audio
                      </label>
                      <input
                        id="audioUpload"
                        type="file"
                        accept="audio/*"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </div>
                  ) : (
                    // Display play/pause button when audio is uploaded
                    <div className="relative flex justify-center items-center mt-4">
                      <button
                        onClick={() => {
                          if (audioRef.current?.paused) {
                            handlePlayAudio();
                          } else {
                            handlePauseAudio();
                          }
                        }}
                        className="w-16 h-16 bg-gray-700 text-white rounded-full flex items-center justify-center hover:bg-gray-600 transition relative"
                      >
                        {/* Play/Pause Icon */}
                        <i className={`fa ${audioRef.current?.paused ? 'fa-play' : 'fa-pause'}`} style={{ fontSize: '24px' }} />

                        {/* X Icon attached to the top-right corner */}
                        <button
                          onClick={e => {
                            e.stopPropagation(); // Prevent triggering play/pause when clicking "X"
                            setSelectedFile(null);
                            setAudioURL(null);
                            setGuildsStatus(prevState => ({
                              ...prevState,
                              audio: null
                            }));
                          }}
                          className="absolute top-0 right-0 w-4 h-4 bg-red-500 text-white rounded-full flex items-center justify-center hover:bg-red-600 transition transform translate-x-1/2 -translate-y-1/2"
                        >
                          <i className="fa fa-times" style={{ fontSize: '10px' }} />
                        </button>
                      </button>

                      {/* Hidden audio element */}
                      <audio ref={audioRef} src={audioURL} controls={false} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-2 mt-4">
            <button onClick={handelSaveClick} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Save Changes
            </button>
            <button className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600" onClick={handelCancelClick}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToggleSwitch;
