import { useContext, useEffect, useState } from "react";
import { guilds } from "../libs/guilds";
import { client } from "../libs/client";
import _selfs from "../libs/selfs";
import { Link, useParams } from "react-router-dom";
import { self } from "../types/Selfs";
import { Voice } from "../types/Voices";
import voices from "../libs/voices";
import GetVoices from "../components/voices";
import { GuildsProps } from "../types/Guilds";
import { MusicSearch } from "../components/search";
import { getmsToTime } from "../libs/libs";
import queueEmpty from "../images/icons/hhh.svg"
import imageDef from "../images/dev.png"
import { player } from "../libs/player";
import { Track } from "../types/Player";
import OupsNotLogined from "../components/OupsNotLogined";
import VoicesLoading from "../components/voiceloading";
import Error from "../components/Error";
import Navbar from "../components/navbar";
import {
    Loopoff,
    Loopqueue,
    Looptrack
} from "../images/icons/repeat";
import GuildsIcones from "../components/guilds";

function Player() {
    let { id, guildId } = useParams();

    const [logined, setLogined] = useState<boolean | null>(client.logined);
    const [Guilds, setGuilds] = useState<{ guilds: GuildsProps[], loaded: boolean | null }>({ guilds: guilds.guilds, loaded: guilds.loaded });
    const [selfs, setSelfs] = useState<{ selfs: self[], loaded: null | boolean }>({ selfs: _selfs.selfs, loaded: _selfs.loaded });
    const [Player, setPlayer] = useState<{ loaded: boolean | null, paused: boolean, tracks: Track[], loop: "off" | "track" | "queue", volume: number, time: number, shuffle: boolean }>({
        loaded: null,
        tracks: player.tracks,
        loop: "off"
        , volume: 20,
        time: 100,
        paused: false,
        shuffle: true
    });
    const [hoveredTrackIndex, setHoveredTrackIndex] = useState<number | null>(null);
    const [Voices, setVoices] = useState<{ voices: Voice[], loaded: boolean | null }>({ voices: voices.Voices, loaded: null });

    const [seekTime, setSeekTime] = useState(Player.time);

    useEffect(() => {

        const handleLogin = async (status: boolean) => {
            setLogined(status);
            if (status && !_selfs.loaded) {
                await _selfs.load();
            }
        };
        const handleSelfsLoad = async (Selfs: self[]) => {
            setSelfs({ loaded: _selfs.loaded, selfs: Selfs });
            if (_selfs.loaded && !guilds.loaded) {
                guilds.fetch(id as string);
            }
        };
        const handleGuildsLoad = async (new_guilds: GuildsProps[]) => {
            setGuilds({ guilds: new_guilds, loaded: guilds.loaded });
            if (guilds.loaded) {
                setVoices({ voices: voices.Voices, loaded: null })
                setPlayer({ loaded: null, paused: false, tracks: [], loop: "off", volume: 20, time: 100, shuffle: false })
                await player.load(id as string, guildId as string);
                await voices.load(guildId as string, id as string);
            }
        };

        const handleVoicesLoad = async (VoiceArray: Voice[]) => {
            setVoices({ voices: VoiceArray, loaded: voices.loaded });
        };
        const handlePlayerLoad = async (playe: any) => {
            setPlayer({ loaded: player.loaded, paused: player.paused, tracks: player.tracks, loop: player.loop, volume: player.volume, time: player.position, shuffle: player.shuffle });
            setSeekTime(player.position);
        }
        client.on('ready', handleLogin);
        _selfs.on('load', handleSelfsLoad);
        guilds.on('guilds', handleGuildsLoad);
        voices.on('loaded', handleVoicesLoad);
        player.on('loaded', handlePlayerLoad)
        if (!client.logined) {
            console.log("loaging in ...");

            client.login();
        }
        return () => {
            client.off('ready', handleLogin);
            _selfs.off('load', handleSelfsLoad);
            guilds.off('guilds', handleGuildsLoad);
            voices.off('loaded', handleVoicesLoad);
        };
    }, [guildId]);

    useEffect(() => {
        voices.on("message", (m) => {
            setVoices({ loaded: true, voices: m });
            console.log("New voices set");
        });

        player.on("message", (newPlayer) => {
            setPlayer({ loaded: true, paused: player.paused, tracks: player.tracks, loop: player.loop, volume: player.volume, time: player.position, shuffle: player.shuffle });
            setSeekTime(player.position);
        });
    }, []);

    useEffect(() => {
        if (guilds.loaded && _selfs.loaded) {
            guilds.fetch(id as string);
        }

    }, [guildId]);

    useEffect(() => {
        const interval = setInterval(() => {
            const track = Player.tracks.find(x => x.playing);
            if (!track) return;
            if (Player.paused) return;
            if (seekTime >= track.duration) {
                clearInterval(interval);
                return;
            }
            setSeekTime(seekTime + 1000);
        }, 1000);
        return () => clearInterval(interval);
    }, [seekTime, Player.paused, Player.tracks]);

    return (
        <>
            <div className="bg-no-repeat bg-center bg-gradient-to-b from-gray-900 to-gray-600 w-full h-screen">
                {logined === false &&
                    <div className="h-screen flex flex-col">
                        <Navbar />
                        <div className="h-full items-center justify-center flex flex-col">
                            <OupsNotLogined />
                        </div>
                    </div>
                }
                {logined === true &&
                    <>
                        {Guilds.loaded == null && <>loading</>}
                        {Guilds.loaded === false &&
                            <>
                                <div className="h-screen flex flex-col">
                                    <Navbar />
                                    <div className="h-full items-center justify-center flex flex-col">
                                        <Error message="Error loading guilds" herf="/dashboard" />
                                    </div>
                                </div>
                            </>
                        }
                        {Guilds.loaded === true &&
                            <div className="h-screen flex flex-col ">
                                <Navbar />
                                <div className="flex flex-row h-full overflow-hidden">
                                <GuildsIcones self={selfs.selfs.find(x => x.id == id)!} activeId={guildId!} guilds={Guilds.guilds} id={id!}></GuildsIcones>
                                    <div id="voices" className="w-[240px] h-full overflow-y-auto flex-shrink-0" >
                                        {!Voices.loaded &&
                                            <div id="voices" className="bg-[#2A2D31] overflow-y-auto p-4 flex-shrink-0 h-full">
                                                {Voices.loaded == null && <VoicesLoading />}
                                                {Voices.loaded == false && <Error message="Error loading voices" herf="/dashboard" />}
                                            </div>
                                        }
                                        {Voices.loaded == true &&
                                            <>
                                                <div id="voices" className="bg-[#2A2D31] overflow-y-auto p-4 flex-shrink-0 h-full">
                                                    {GetVoices(Voices.voices)}
                                                </div>
                                            </>
                                        }

                                    </div>
                                    {!voices.Voices.find(x => x.members.find(ee => ee.id == client.id)) && !voices.Voices.find(v => v.members.find(m => m.id == id)) &&
                                        <div className="w-full h-full flex items-center justify-center">
                                            <div className="bg-[#202225] p-8 rounded-lg">
                                                <h1 className="text-white text-2xl font-bold mb-4">Waiting for voice channel</h1>
                                                <p className="text-gray-300">Please join a voice channel to start using the app.</p>
                                            </div>
                                        </div>
                                    }
                                    {voices.Voices.find(x => x.members.find(ee => ee.id == client.id)) && !voices.Voices.find(v => v.members.find(m => m.id == id)) &&
                                        <div className="w-full h-full flex items-center justify-center">
                                            <div className="bg-[#202225] p-8 rounded-lg">
                                                <h1 className="text-white text-2xl font-bold mb-4">Join {voices.Voices.find(x => x.members.find(ee => ee.id == client.id))?.name}</h1>
                                                <p className="text-gray-300">Click This button to join {voices.Voices.find(x => x.members.find(ee => ee.id == client.id))?.name} voice channel.</p>
                                                <button onClick={() => player.joinVoice(id!, voices.Voices.find(x => x.members.find(ee => ee.id == client.id))?.id as string, guildId!)} className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4 hover:bg-blue-600">Join</button>
                                            </div>
                                        </div>
                                    }

                                    {voices.Voices.find(v => v.members.find(m => m.id == id)) &&
                                        <div className="w-full h-full flex flex-col items-center justify-center overflow-hidden">
                                            <div className="relative w-full max-w-lg mx-auto mt-10 ">
                                                <div className="sticky top-0 z-10 ">
                                                    <MusicSearch />
                                                </div>
                                            </div>

                                            {Player.tracks.length > 0 &&
                                                <>
                                                    <div
                                                        id="queue"
                                                        className="mt-10 w-full mx-auto text-white p-4 rounded-lg shadow-lg overflow-y-scroll flex-grow overflow-y-auto p-4 flex-shrink-0 h-[calc(100vh-300px)]">
                                                        <h2 className="text-2xl font-bold mb-4">Queue</h2>
                                                        <div className="divide-y divide-gray-700">
                                                            {Player.tracks.map((track: Track, i: number) => (
                                                                <div
                                                                    onMouseEnter={() => setHoveredTrackIndex(i)}
                                                                    onMouseLeave={() => setHoveredTrackIndex(null)}
                                                                    key={i} className="flex items-center py-4 rounded-md hover:bg-gray-800 flex-grow ">

                                                                    {hoveredTrackIndex === i && !track.playing && <span onClick={() => { player.skipTo(id!, guildId!, i) }} className="w-8 mr-1 flex items-center justify-center" ><svg data-encore-id="icon" role="img" aria-hidden="true" className="w-4 h-4 fill-current text-white-400 hover:cursor-pointer" viewBox="0 0 24 24"><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg></span>}
                                                                    {!track.playing && hoveredTrackIndex !== i && <span className={`text-gray-400 w-8 mr-1 text-center`}>{i + 1}</span>}
                                                                    {Player.paused && track.playing && hoveredTrackIndex !== i && <span className={`text-green-400 w-8 mr-1 text-center`}>{i + 1}</span>}
                                                                    {track.playing && !Player.paused && hoveredTrackIndex !== i && <span className="w-8 mr-1 flex items-center justify-center"><img className="w-4 h-4 fill-current" src="https://open.spotifycdn.com/cdn/images/equaliser-animated-green.f5eb96f2.gif"></img></span>}
                                                                    {track.playing && hoveredTrackIndex === i &&
                                                                        !Player.paused && <span onClick={() => player.pause(id!, guildId!)} className="w-8 mr-1 flex items-center justify-center"><svg data-encore-id="icon" role="img" aria-hidden="true" className="w-4 h-4 fill-current text-white-400 hover:cursor-pointer" viewBox="0 0 24 24"><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg></span>}
                                                                    {track.playing && hoveredTrackIndex === i && Player.paused &&
                                                                        <span onClick={() => player.resume(id!, guildId!)} className="w-8 mr-1 flex items-center justify-center"><svg data-encore-id="icon" role="img" aria-hidden="true" className="w-4 h-4 fill-current text-white-400 hover:cursor-pointer" viewBox="0 0 24 24"><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg></span>
                                                                    }
                                                                    <img src={track.thumbnail ?? imageDef} alt="Album Cover" className="w-12 h-12 rounded-md mr-4" />
                                                                    <div className="">
                                                                        <div className={`w-48 truncate overflow-hidden text-lg font-semibold ${track.playing ? "text-green-500" : ""}`}>{track.title}</div>
                                                                        <div className="truncate font-semibold text-sm text-gray-400">{track.author.name}</div>
                                                                    </div>
                                                                    <span className="text-sm text-gray-400 mr-4 ml-auto">{getmsToTime(track.duration)}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {Player.tracks.find(x => x.playing) && <>
                                                        <div id="queue-manager-footer" className="bg-gray-900 h-[80px] w-full flex-shrink-0 flex items-center justify-between px-4 bottom-0 ">

                                                            <div className="w-1/3 flex items-center truncate overflow-hidden ">
                                                                <img
                                                                    src={Player.tracks.find((x) => x.playing)?.thumbnail ?? imageDef}
                                                                    alt="Album Cover"
                                                                    className="w-10 h-10 rounded-sm mr-3"
                                                                />
                                                                <div className="flex flex-col">
                                                                    <div className="text-white font-semibold truncate ">
                                                                        {Player.tracks.find((x) => x.playing)?.title}
                                                                    </div>
                                                                    <div className="text-gray-400 text-sm truncate">
                                                                        {Player.tracks.find((x) => x.playing)?.author.name}
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center ml-4">
                                                                    <button className="text-gray-400 hover:text-white">
                                                                        <i className="fas fa-heart"></i>
                                                                    </button>
                                                                    <button className="text-gray-400 hover:text-white ml-2">
                                                                        <i className="fas fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>


                                                            <div className="w-1/3 flex flex-col items-center">
                                                                <div className="flex items-center space-x-4">
                                                                    <button className="text-gray-400 hover:text-white">
                                                                        <i className="fas fa-random"></i>
                                                                    </button>
                                                                    <button onClick={() => { player.back(id!, guildId!) }} className="text-gray-400 hover:text-white">
                                                                        <i className="fas fa-step-backward"></i>
                                                                    </button>
                                                                    {Player.paused &&
                                                                        <button onClick={() => { player.resume(id!, guildId!) }} className="text-white">
                                                                            <i className="fas fa-play-circle text-4xl"></i>
                                                                        </button>}
                                                                    {!Player.paused &&
                                                                        <button onClick={() => { player.pause(id!, guildId!) }} className="text-white">
                                                                            <i className="fas fa-pause-circle text-4xl"></i>
                                                                        </button>}
                                                                    <button onClick={() => { player.skip(id!, guildId!) }} className="text-gray-400 hover:text-white ">
                                                                        <i className="fas fa-step-forward"></i>
                                                                    </button>
                                                                    {Player.loop == "track" &&
                                                                        <button onClick={() => { player.setLoop(id!, guildId!, "off") }} className="text-gray-400 hover:text-white w-4 h-4">
                                                                            <Looptrack />
                                                                        </button>
                                                                    }

                                                                    {Player.loop == "off" &&
                                                                        <button onClick={() => { player.setLoop(id!, guildId!, "queue") }} className="text-gray-400 hover:text-white w-4 h-4">
                                                                            <Loopoff />
                                                                        </button>
                                                                    }
                                                                    {Player.loop == "queue" &&
                                                                        <button onClick={() => { player.setLoop(id!, guildId!, "track") }} className="text-green-400 hover:text-white w-4 h-4">
                                                                            <Loopqueue />
                                                                        </button>
                                                                    }


                                                                </div>
                                                                <div className="flex items-center justify-between w-full mt-2 ">
                                                                    <span className="text-sm text-gray-400">
                                                                        {getmsToTime(seekTime ?? 0)}
                                                                    </span>
                                                                    <input
                                                                        type="range"
                                                                        min="0"
                                                                        max={Player.tracks.find((x) => x.playing)?.duration ?? 0}
                                                                        value={seekTime}
                                                                        step="any"
                                                                        onChange={(e) => {
                                                                            const newTime = parseFloat(e.target.value);
                                                                            setSeekTime(Math.floor(newTime))
                                                                        }}
                                                                        onMouseUp={(e) => {
                                                                            const newTime = parseFloat((e.target as HTMLInputElement).value);
                                                                            console.log(newTime);
                                                                            player.seek(id!, guildId!, newTime)

                                                                            setSeekTime(Math.floor(newTime));
                                                                        }}

                                                                        className="w-full mx-4 accent-white-500 h-1 cursor-pointer hover:accent-green-500"
                                                                    />
                                                                    <span className="text-sm text-gray-400">
                                                                        {getmsToTime(Player.tracks.find((x) => x.playing)?.duration ?? 0)}
                                                                    </span>
                                                                </div>
                                                            </div>


                                                            <div className="w-1/3 flex items-center justify-end space-x-4 ">
                                                                <button className="text-gray-400 hover:text-white">
                                                                    <i className="fas fa-list"></i>
                                                                </button>
                                                                <button className="text-gray-400 hover:text-white">
                                                                    <i className="fas fa-broadcast-tower"></i>
                                                                </button>
                                                                <button className="text-gray-400 hover:text-white">
                                                                    <i className="fas fa-volume-up"></i>
                                                                </button>
                                                                <button className="text-gray-400 hover:text-white">
                                                                    <i className="fas fa-sliders-h"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </>}
                                                </>
                                            }
                                            {Player.tracks.length == 0 &&
                                                <div
                                                    id="empty-queue"
                                                    className="mt-10 w-full mx-auto text-center text-white p-4 rounded-lg shadow-lg flex items-center justify-center"
                                                    style={{
                                                        margin: '0 40px', marginTop: "30px",
                                                        height: 'calc(100vh - 160px)'
                                                    }}>

                                                    <div>
                                                        <img className="mx-auto mb-4 w-16 h-16 text-gray-400" src={queueEmpty} alt="queue" />
                                                        <h3 className="text-xl font-semibold">Your queue is empty</h3>
                                                        <p className="text-gray-400 mt-2">Start adding some tracks to your queue to enjoy your music!</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </>
    )
}

export default Player;



