import axios from "axios";
import EventEmitter from "events";
import { client } from "./client";
import { Track } from "../types/Player";
import io, { Socket } from 'socket.io-client';

const API_URL = "https://api.selftouns.shop/api"
class Player extends EventEmitter {
    tracks: Track[];
    loaded: boolean | null;
    guildId: string|null;
    socket: Socket|null = null;
    session: string|null = null;
    volume: number = 100;
    paused: boolean = false;
    position: number = 0;
    autoplay: boolean = false;
    shuffle: boolean = false;
    loop: "off" | "track" | "queue" = "off";
    connected: boolean = false;
    constructor() {
        super()
        this.guildId = null
        this.loaded = null
        this.tracks = []
    }

    async search(id: string, guildId: string, query: string) {
        const res = await axios.get(`${API_URL}/v1/music/${id}/search?guildId=${guildId}`, {
            params: {
                query: query
            },
            headers: {
                Authorization: `${client.token}`
            }
        });
        if(res.data.ok){
            const tracks = res.data.tracks as Track[]
            return {
                tracks,
                searchtype: res.data.searchtype,
                playlist: res.data.playlist
            };
        } else {
            return {
                tracks: [],
                searchtype: "search",
                playlist: null
            }
        }
        
    }
    async play(id: String | undefined, track: String , guildId: string) {
        const res = await axios.get(`${API_URL}/v1/music/${id}/play?guildId=${guildId}`,
        {
            params: {
                track :track
            },
            headers: {
                Authorization: `${client.token}`,
            }
        })
    }
    async joinVoice(id: string, channelId: string, guildId: string){
        const res = await axios.get(`${API_URL}/v1/music/${id}/joinvoice?channelId=${channelId}&guildId=${guildId}`, {
            headers: {
                Authorization: `${client.token}`
            }
        })
    }
    async skipTo(id: string, guildId: string, index: number){
        const res = await axios.get(`${API_URL}/v1/music/${id}/skipto?guildId=${guildId}&index=${index}`, {
            headers: {
                Authorization: `${client.token}`
            }
        })
    }
    async pause(id: string, guildId: string){
        const res = await axios.get(`${API_URL}/v1/music/${id}/pause?guildId=${guildId}`, {
            headers: {
                Authorization: `${client.token}`
            }
        })
    }
    async resume(id: string, guildId: string){
        const res = await axios.get(`${API_URL}/v1/music/${id}/resume?guildId=${guildId}`, {
            headers: {
                Authorization: `${client.token}`
            }
        })
    }
    async seek(id: string, guildId: string, position: number){
        const res = await axios.get(`${API_URL}/v1/music/${id}/seek?guildId=${guildId}&position=${position}`, {
            headers: {
                Authorization: `${client.token}`
            }
        })
    }
    async setLoop(id: string, guildId: string, loop: "off" | "track" | "queue"){
        const res = await axios.get(`${API_URL}/v1/music/${id}/loop?guildId=${guildId}&loop=${loop}`, {
            headers: {
                Authorization: `${client.token}`
            }
        })
    }
    async skip(id: string, guildId: string){
        const res = await axios.get(`${API_URL}/v1/music/${id}/skip?guildId=${guildId}`, {
            headers: {
                Authorization: `${client.token}`
            }
        })
    }
    async back(id: string, guildId: string){
        const res = await axios.get(`${API_URL}/v1/music/${id}/back?guildId=${guildId}`, {
            headers: {
                Authorization: `${client.token}`
            }
        })
    }
    async load(id: string, guildId: string){
       
        const res = await axios.get(`${API_URL}/queue/${id}/${guildId}/`, {
            headers: {
                Authorization: `${client.getToken()}`
            }
        });
        if(res.data.ok) {
            this.guildId = guildId
            this.loaded = true;
            this.tracks = res.data.queue.tracks;
            this.loop = res.data.queue.loop;
            this.volume = res.data.queue.volume;
            this.shuffle = res.data.queue.shuffle;
            this.paused = res.data.queue.paused;
            this.position = res.data.queue.position;
            this.autoplay = res.data.queue.autoplay;
            this.connected = res.data.queue.connected;
            this.startSocket(res.data.session)
        } else {
            this.loaded = false;
            this.tracks = [];
        }
        this.emit("loaded", this)
    }
    private startSocket(session: string){
        if(this.socket && this.socket.connected){
          if(this.session == session) return;
          this.socket.disconnect();
        }
        this.session = session;
        this.socket = io(client.SOCKET_URL, {
         query: {
            session: session,
            type: "player"
         }
        });
        this.socket.on("connect", () => {
         console.log("player Socket Connected to socket");
        });
        this.socket.on("disconnect", () => {
         console.log("player Socket Disconnected from socket");
        });

        this.socket.on("message", async (m) => {
            this.tracks = m.tracks;
            this.loop = m.loop;
            this.volume = m.volume;
            this.shuffle = m.shuffle;
            this.paused = m.paused;
            this.position = m.position;
            this.autoplay = m.autoplay;
            this.connected = m.connected;
            this.emit("message", m)
        });

   }

}


const player = new Player()
export {
    Player,
    player,
}
