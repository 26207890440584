import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { client } from "../libs/client";
import { toast } from 'react-toastify';

function Login() {
    const { token } = useParams();
    const navigate = useNavigate();
    let close: boolean = false
    window.addEventListener('load', () => {
        if (window.opener && window.opener.location.hostname === window.location.hostname) {
            close = true
        }
    });
    useEffect(() => {
        const promise = new Promise(async (resolve, reject) => {
            localStorage.setItem('token', `${token}`);
            await client.getData()
            .then((res: boolean) => {
                if (res) {
                    if (close) {
                        window.close();
                    }
                    navigate('/dashboard');
                    resolve(res);
                } else {
                    reject(res);
                    navigate('/');
                }
            });
        });
        toast.promise(promise, {
            pending: 'Logging in',
            success: 'Logged in',
            error: 'Error logging in'
        });
    }, [token, navigate]);
    return (
        <div>
        </div>
    )
}

export default Login;