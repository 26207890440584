
const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-800 text-white py-12">
        <div className="container mx-auto px-6 md:px-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h2 className="text-2xl font-semibold mb-4 text-blue-800">SelfTouns</h2>
              <p className="text-gray-400">
                A high-quality music bot supporting all major platforms like Spotify, Tidal, Soundcloud, and more. Enjoy seamless music playback with filters, autoplay, and playlists.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4 text-blue-800">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="/" className="hover:text-blue-400">Home Page</a></li>
                <li><a href="#" className="hover:text-blue-400">Status</a></li>
                <li><a href="#" className="hover:text-blue-400">Creators</a></li>
                <li><a href="#" className="hover:text-blue-400">GitHub</a></li>
                <li><a href="#" className="hover:text-blue-400">Discord</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-blue-400 mb-4 text-blue-800">Rules</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-blue-400">Legal Terms</a></li>
                <li><a href="#" className="hover:text-blue-400">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 flex flex-col md:flex-row justify-between items-center border-t border-gray-700 pt-6">
            <div className="text-center md:text-left">
              <p className="text-gray-400">
                &copy; 2019 - 2024 SelfTouns's team. All rights reserved.
              </p>
            </div>
            <div className="flex space-x-4 mt-6 md:mt-0">
              <a href="#" aria-label="Discord" className="hover:text-blue-400">
                <i className="fab fa-discord"></i>
              </a>
              <a href="#" aria-label="Twitter" className="hover:text-blue-400">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" aria-label="GitHub" className="hover:text-blue-400">
                <i className="fab fa-github"></i>
              </a>
              <a href="#" aria-label="Language" className="hover:text-blue-400">
                <i className="fas fa-language"></i>
              </a>
              <a href="#" aria-label="Reddit" className="hover:text-blue-400">
                <i className="fab fa-reddit"></i>
              </a>
              <a href="#" aria-label="YouTube" className="hover:text-blue-400">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    )
};

export default Footer;
