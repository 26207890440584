import { client } from "./client";
const Oauth2URL = `https://discord.com/oauth2/authorize?client_id=1284926619911327744&response_type=code&redirect_uri=https%3A%2F%2Fapi.selftouns.shop%2Fapi%2Flogin&scope=identify`
const HandlLogin = () => {
    const newWindow = window.open(
        Oauth2URL,
        "_blank",   
        "width=600,height=600",
    );
    const checkWindowClosed = setInterval(() => {
        if (newWindow  && newWindow.closed) {
          clearInterval(checkWindowClosed);
          console.log("Window closed. Attempting to log in.");
          client.login();
        }
      }, 500);
    };

    function formatTimestamp(timestamp: number) {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    
function statusClasses(status: string | undefined) {
    if (status === "online") return "bg-green-500"
    if (status === "dnd") return "bg-red-500"
    if (status === "idle") return "bg-yellow-500"
    if (status === "offline") return "bg-gray-500"
    return "bg-gray-500"
}

export {
    HandlLogin,
    Oauth2URL,
    formatTimestamp,
    statusClasses,
}

