import { useEffect, useState } from "react";
import { player } from "../libs/player";
import { Track } from "../types/Player";
import { useParams } from "react-router-dom";
import { getmsToTime } from "../libs/libs";
import defimg from "../images/dev.png"

interface SearchResults {
    tracks: Track[];
    searchtype: string | null;
    playlist: any
}


function alltracksTime(Tracks: Track[]) {
    const totalDuration = Tracks.reduce((acc, track) => acc + track.duration, 0);
    return getmsToTime(totalDuration);
}

function MusicSearch() {
    let { id, guildId } = useParams();
    const [query, setQuery] = useState('');
    const [results, setResults] = useState<SearchResults>({ playlist: null, searchtype: null, tracks: [] });
    const [debouncedQuery, setDebouncedQuery] = useState(query);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handler = setTimeout(() => {
            setResults({ tracks: [], playlist: null, searchtype: null });
            setDebouncedQuery(query);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    useEffect(() => {
        const fetchResults = async () => {
            if (debouncedQuery) {
                setLoading(true);
                setResults({ tracks: [], playlist: null, searchtype: null });
                try {
                    const res = await player.search(id!, guildId!, debouncedQuery);
                    if(query) {
                        setResults(res);
                    } else {
                        setResults({ tracks: [], playlist: null, searchtype: null });
                        setLoading(false);
                    }
                    
                } catch (error) {
                    console.error("Error fetching results:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setResults({ tracks: [], playlist: null, searchtype: null });
                setLoading(false);
            }
        };

        fetchResults();
    }, [debouncedQuery]);

    return (
        <>
            <input
                type="text"
                className="w-full p-3 pl-10 rounded-lg bg-gray-800 text-white"
                placeholder="Search for music..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {

                    }
                }}
                autoComplete="off"
            />
            {(loading || results.searchtype) && (

                <div className="absolute z-10 w-full bg-gray-800 border border-gray-700 rounded-lg mt-2 shadow-lg">
                    {loading ? (
                        Array(5).fill(0).map((_, index) => (
                            <div key={index} className="flex items-center p-3 cursor-pointer">
                                <div className="w-12 h-12 bg-gray-700 rounded mr-3 animate-pulse"></div>
                                <div className="flex-1">
                                    <div className="h-4 bg-gray-700 rounded w-3/4 mb-2 animate-pulse"></div>
                                    <div className="h-3 bg-gray-600 rounded w-1/2 animate-pulse"></div>
                                </div>
                                <div className="h-3 bg-gray-600 rounded w-10 animate-pulse"></div>
                            </div>
                        ))
                    ) : results.searchtype === "playlist" && results.playlist ? (
                        <div
                            className="flex items-center p-3 hover:bg-gray-700 cursor-pointer text-white">
                            <img
                                src={results.playlist.thumbnail ?? defimg}
                                alt={results.playlist.title}
                                className="w-12 h-12 object-cover rounded mr-3"
                            />
                            <div className="flex-1">
                                <div className="truncate overflow-hidden font-semibold">{results.playlist.title}</div>
                                <div className="text-sm text-gray-400">
                                    {results.tracks.length} tracks
                                </div>
                            </div>
                            <div className="text-sm text-gray-400 mr-5 w-16 text-right">{alltracksTime(results.tracks)}</div>
                            {results.tracks.length > 0 &&
                                <div className="flex items-center">
                                    <span onClick={() => {
                                        player.play(id!, results.playlist.uri, guildId!)
                                        setQuery("")
                                    }} className="text-sm text-gray-400 mr-2 cursor-pointer">
                                        <i className="fa-solid fa-play "></i>
                                    </span>
                                    <span className="text-sm text-gray-400 mr-2 cursor-pointer">
                                        <i className="fa-solid fa-list-check"></i>
                                    </span>
                                </div>
                            }
                        </div>
                    ) : (
                        <>
                            {results.tracks.length > 0 &&
                                results.tracks.map((result, i) => (
                                    <div onDoubleClick={() => {


                                    }}
                                        key={result.title as string}
                                        className="flex items-center p-3 hover:bg-gray-700 text-white">
                                        <img
                                            src={result.thumbnail ?? defimg}
                                            alt={result.title}
                                            className="w-12 h-12 object-cover rounded mr-3"
                                        />
                                        <div className="flex-1 truncate overflow-hidden ">
                                            <div className=" font-semibold">{result.title}</div>
                                            <div className="text-sm text-gray-400">{result.author.name}</div>
                                        </div>
                                        <div className="text-sm text-gray-400 mr-5 w-16 text-right">{getmsToTime(result.duration)}</div>
                                        <div className="flex items-center">
                                            <span onClick={() => {
                                                player.play(id!, result.link, guildId!)
                                                setQuery("")
                                            }} className="text-sm text-gray-400 mr-2 cursor-pointer">
                                                <i className="fa-solid fa-play "></i>
                                            </span>
                                            <span className="text-sm text-gray-400 mr-2 cursor-pointer">
                                                <i className="fa-solid fa-list-check"></i>
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                            {results.tracks.length === 0 && !loading &&
                                <div className="flex items-center p-3 hover:bg-gray-700 cursor-pointer text-white">
                                    No results found.
                                </div>
                            }
                        </>
                    )

                    }

                </div>
            )}
        </>
    )
}

export {
    MusicSearch
}