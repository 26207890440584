
import React, { useState, useRef, useEffect } from 'react';

import { initializePaddle, Paddle } from '@paddle/paddle-js';


const Test = () => {

  const [paddle, setPaddle] = useState<Paddle>();

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({ token: 'live_2f7f317250124221669ff65dcd9' }).then(
      (paddleInstance: Paddle | undefined) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, []);

  // Callback to open a checkout
  const openCheckout = () => {
    paddle?.Checkout.open({
      items: [{ priceId: 'pri_01j5jvy6n25gysp4w5kn4rj0qg', quantity: 1 }],
    });
  };

  return (
    <div className="p-4">
      <button onClick={openCheckout}>Open Checkout</button>
    </div>
  );
};

export default Test;