import imge1 from "../images/icons/1.webp"
import imge2 from "../images/icons/2.webp"
import imge3 from "../images/icons/3.webp"


interface SectionProps {
    imageSrc: string;
    title: string;
    description: string;
    reverse?: boolean;
}

const Section: React.FC<SectionProps> = ({ imageSrc, title, description, reverse = false }) => {
    return (
        <div className={`flex flex-col ${reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center p-8`}>
            <div className={`w-full lg:w-1/2 flex justify-center lg:justify-${reverse ? 'end' : 'start'} mb-6 lg:mb-0`}>
                <img
                    src={imageSrc}
                    alt="Illustration"
                    className="w-3/4 lg:w-full max-w-sm rounded-lg shadow-lg transition-transform duration-500 ease-in-out transform hover:scale-105"
                />
            </div>
            <div className="w-full lg:w-1/2 text-center lg:text-left px-6 lg:px-12">
                <h2 className="text-3xl font-bold text-blue-900 mb-4">{title}</h2>
                <p className=" leading-relaxed text-white">{description}</p>
            </div>
        </div>
    );
};


export default function Sections() {
    return (
        //add pading
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 staggered-animation staggered-delay-3">
            <Section
                imageSrc={imge1}
                title="Epic Music Experienc"
                description="Rock out with your friends right on your Discord server with SelfTouns! Dive into the ultimate music experience, sharing your favorite tunes seamlessly and in perfect sync."
            />
            <Section
                imageSrc={imge2}
                title="Advanced Music Streaming"
                description="Enjoy seamless music streaming directly on your Discord server with our advanced latency management. Stream your favorite tracks from the web with minimal delay, ensuring high-quality sound for all your friends on the server."
                reverse
            />
            <Section
                imageSrc={imge3}
                title="All-in-One Music Streaming"
                description="Access and stream music from Spotify, Deezer, Apple Music, and more, directly within your Discord server. Our bot seamlessly integrates with your favorite platforms, providing high-quality sound with minimal delay, ensuring everyone enjoys their favorite tunes together."
            />
        </div>
    )
}