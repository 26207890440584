import EventEmitter from "events";
import { client } from "./client";
import axios from "axios";
import { Voice } from "../types/Voices";
import io, { Socket } from 'socket.io-client';


class Voices extends EventEmitter {
    Voices: Voice[] = [];
    guildId: string|null;
    loaded: boolean|null;
    socket: Socket|null = null;
    session: string|null = null;
    constructor(){
        super();
        this.Voices = [];
        this.guildId = null;
        this.loaded = null;
    }
   async load(guildId: string, clientId: string){      
        this.Voices = [];
        if (!client.token) return;
        console.log(guildId);
        
        const req = await axios.get(`${client.API_URL}/voices/${guildId}/${clientId}`, {
            headers: {
                Authorization: `${client.token}`
            }
        });
        if (req.data.ok) {
            this.Voices = req.data.voices;
            this.guildId = guildId;
            this.loaded = true;
            this.emit("loaded", this.Voices);
            this.startSocket(req.data.session);          
        } else {
            this.Voices = [];
            this.guildId = null;
            this.loaded = false;
            this.emit("loaded", []);
        }
    }
   private startSocket(session: string){
        if(this.socket && this.socket.connected){
          if(this.session == session) return;
          this.socket.disconnect();
        }
        this.session = session;
        this.socket = io(client.SOCKET_URL, {
         query: {
            session: session,
            type: "voices"
         }
        });
        
        this.socket.on("connect", () => {
         console.log("Voices Socket Connected to socket");
        });
        this.socket.on("disconnect", () => {
         console.log("Voices Socket Disconnected from socket");
        });
        this.socket.on("message", async (m) => {
            this.Voices = m;
            console.log("new voices: "+m);
            this.emit("message", m);
        });
   }

}

const voices = new Voices();
export default voices;