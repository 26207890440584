function getmsToTime(duration: number) {
    let time = ""
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    if (hours > 0) time += `${hours.toString().padStart(2, '0')}:`
    time += `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    return time;
}


export {
    getmsToTime
}