import { Link } from "react-router-dom"

interface ErrorProps{
    message: string,
    herf: string
}
const Error = ({message,herf}:ErrorProps) =>{
    return (
        <div className="flex flex-col items-center justify-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center max-w-md mx-auto">
        <strong className="font-bold text-center block">Error!</strong>
        <span className="block sm:inline font-bold">{message}</span>
        <Link to={herf}>
        <button 
            className="mt-4 bg-indigo-500 text-white font-semibold py-2 px-4 rounded hover:bg-indigo-600 transition-colors"
        >
            Go to Dashboard
        </button>
        </Link>
    </div>
    )
}

export default Error