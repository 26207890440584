import { Link } from "react-router-dom"
import { self } from "../types/Selfs"




const sidebar = (id: string) => [
    {
        category: {
            name: "GENERAL",
            items: [
                {
                    name: "Overview",
                    icon: <i className="fa-solid fa-eye"></i>,
                    link: `/dashboard/${id}`,
                    id: "overview",
                    soon: false,
                    isNew: false,
                },
                {
                    name: "Manage",
                    icon: <i className="fa-solid fa-gear"></i>,
                    link: `/dashboard/${id}/manage`,
                    id: "manage",
                    soon: false,
                    isNew: false,
                },
            ]
        }
    },
    {
        category: {
            name: "AUTOMATION",
            items: [
                {
                    name: "Auto Responder",
                    icon: <i className="fa-solid fa-reply"></i>,
                    link: `/dashboard/${id}`,
                    id: "auto_reaction",
                    soon: false,
                    isNew: false,
                },
                {
                    name: "Auto Reaction",
                    icon: <i className="fa-solid fa-heart"></i>,
                    link: `/dashboard/${id}`,
                    id: "auto_responder",
                    soon: false,
                    isNew: true,
                },

            ]
        }
    },
    {
        category: {
            name: "CHECKER",
            items: [
                {
                    name: "Check Voice",
                    icon: <i className="fas fa-volume-up"></i>,
                    link: `/dashboard/${id}`,
                    id: "check_voice",
                    soon: true,
                    isNew: false,
                },
                {
                    name: "Check Channel",
                    icon: <i className="fa-solid fa-hashtag"></i>,
                    link: `/dashboard/${id}`,
                    id: "check_channel",
                    soon: true,
                    isNew: false,
                },
                {
                    name: "Check Staff",
                    icon: <i className="fas fa-crosshairs"></i>,
                    link: `/dashboard/${id}`,
                    id: "check_staff",
                    soon: true,
                    isNew: false,
                },

            ]
        }
    },
]

interface sidebarProps {
    self: self
    isOpen: boolean
    active: "overview" | "manage" | "auto_reaction" | "auto_responder"|"check_voice"|"check_channel"|"check_staff";
    onClose?: () => void
    onOpen?: () => void
    disableScrollLock?: boolean
    disableEscapeKeyDown?: boolean
    TransitionProps?: object
    className?: string
}

function Sidebar_Items(props: sidebarProps) {
    return (

        <>
            {
                sidebar(props.self.id).map((category) => (
                    <div key={category.category.name}>
                        <div className="w-full flex items-center cursor-pointer p-1 group hover:text-white">
                            <div className="text-[10px] ml-3 mr-1 font-22px text-[#878787] group-hover:text-white">
                                <i className="fa-solid fa-chevron-down"></i>
                            </div>
                            <h3 className="text-[11px] text-[#878787] group-hover:text-white">
                                {category.category.name}
                            </h3>
                        </div>

                        <div className="w-full flex flex-col items-center text-center overflow-scroll pr-3 pl-3 scrollbar-hide">
                            {
                                category.category.items.map((item) => (
                                    <Link
                                        to={item.link}
                                        className={`w-full mb-1 flex items-center justify-between p-1 rounded-lg cursor-pointer ${props.active == item.id ? "bg-blue-600" : "hover:bg-gray-700"
                                            }`}>
                                        <div className="flex items-center">
                                            <div className="text-white pl-1 pr-2">{item.icon}</div>
                                            <div className="text-white text-lg mr-2 text-sm text-nowrap">{item.name}</div>
                                        </div>
                                        {item.soon && <span className="bg-yellow-200 text-yellow-600 font-bold rounded-full px-2 py-0.5 rounded-[4px] text-[10px] mx-[10px]">SOON</span>}
                                        {item.isNew && <span className="bg-pink-200 text-[#fb4fdc] font-bold rounded-full px-2 py-0.5 rounded-[4px] text-[10px] mx-[10px]">NEW</span>}
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default Sidebar_Items;