import EventEmitter from "events";
import { client } from "./client";
import axios from "axios";
import { GuildsProps } from "../types/Guilds";

class Guilds extends EventEmitter {
    guilds: GuildsProps[] = [];
    loaded: boolean|null = null;
    selfId: string|null = null;
    constructor() {
        super();
        this.guilds = []
    }
    async fetch(id: string) {
        this.guilds = [];
        this.loaded = null;
        this.selfId = id;
        const response = await axios.get(`${client.API_URL}/guilds/${id}`, {
            headers: {
                Authorization: `${client.token}`
            }
        });
        if (response.data.ok) {
            const guilds = response.data.guilds;
            this.guilds = guilds;
            this.loaded = true;
            this.emit("guilds", guilds);
        } else {
            this.loaded = false;
            this.emit("guilds", []);
        }
    }
}

const guilds = new Guilds();

export {
    guilds,
};