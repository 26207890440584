import { HandlLogin } from '../libs/functions'
function OupsNotLogined() {
    return (
<div className="flex justify-center items-center h-full">
    <div>
        <div className="central-body font-bold bg-gray-800 text-white p-6 rounded-md shadow-md w-200">
            <h1 className="font-bold text-2xl">Oops! It looks like something's missing</h1>
            <p className="text-gray-300 text-sm font-normal mt-2">
                You need to login with your Discord account to access this feature.
            </p>
            <div
                onClick={HandlLogin}
                className="bg-indigo-500 text-white font-semibold py-2 px-4 rounded cursor-pointer flex items-center justify-center hover:bg-indigo-600 transition-colors mt-4"
            >
                <i className="fab fa-discord mr-2"></i> Login using Discord
            </div>
        </div>
    </div>
</div>
)
}

export default OupsNotLogined;