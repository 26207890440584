function LockedChannel(){
    return (
       
            <svg
                className="icon_d8bfb3"
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M6 9h1V6a5 5 0 0 1 10 0v3h1a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3Zm9-3v3H9V6a3 3 0 1 1 6 0Zm-1 8a2 2 0 0 1-1 1.73V18a1 1 0 1 1-2 0v-2.27A2 2 0 1 1 14 14Z"
                    clipRule="evenodd"
                    className=""
                />
            </svg>
       
    )
}

function ChaneelOpned() {
    return (
        <svg
            className="icon_d8bfb3"
            aria-hidden="true"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="M12 3a1 1 0 0 0-1-1h-.06a1 1 0 0 0-.74.32L5.92 7H3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.92l4.28 4.68a1 1 0 0 0 .74.32H11a1 1 0 0 0 1-1V3ZM15.1 20.75c-.58.14-1.1-.33-1.1-.92v-.03c0-.5.37-.92.85-1.05a7 7 0 0 0 0-13.5A1.11 1.11 0 0 1 14 4.2v-.03c0-.6.52-1.06 1.1-.92a9 9 0 0 1 0 17.5Z"
                className=""
            />
            <path
                fill="currentColor"
                d="M15.16 16.51c-.57.28-1.16-.2-1.16-.83v-.14c0-.43.28-.8.63-1.02a3 3 0 0 0 0-5.04c-.35-.23-.63-.6-.63-1.02v-.14c0-.63.59-1.1 1.16-.83a5 5 0 0 1 0 9.02Z"
                className=""
            />
        </svg>
    );
}

function ChannelHeden(){
    return(
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_312_34)">
<path d="M40 14.5449C16.9018 14.5449 0 39.9995 0 39.9995C0 39.9995 16.9018 65.454 40 65.454C63.0982 65.454 80 39.9995 80 39.9995C80 39.9995 63.0982 14.5449 40 14.5449ZM40 54.5449C31.9673 54.5449 25.4545 48.0358 25.4545 39.9995C25.4545 31.9631 31.9673 25.454 40 25.454C48.0327 25.454 54.5455 31.9631 54.5455 39.9995C54.5455 48.0358 48.0327 54.5449 40 54.5449Z" fill="#A7A7AF"/>
<path d="M39.9993 47.272C44.016 47.272 47.272 44.016 47.272 39.9993C47.272 35.9826 44.016 32.7266 39.9993 32.7266C35.9826 32.7266 32.7266 35.9826 32.7266 39.9993C32.7266 44.016 35.9826 47.272 39.9993 47.272Z" fill="#A7A7AF"/>
</g>
<path d="M71.6832 4L4 71.7857L9.2064 77L76.8896 9.21428L71.6832 4Z" fill="#E93848"/>
<defs>
<clipPath id="clip0_312_34">
<rect width="80" height="80" fill="white"/>
</clipPath>
</defs>
</svg>
    )
}

export {
    LockedChannel,
    ChaneelOpned,
    ChannelHeden
}
